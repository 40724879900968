import React, { useState, useEffect } from "react";
import Base from "../core/Base";
import { isAutheticated } from "../auth/helper";
import { FaStar } from "react-icons/fa";
import { Table } from "react-bootstrap";

import { showAllReview, deleteReview } from "./helper/adminapicall";
import { Redirect, Link } from "react-router-dom";

const ShowReview = () => {
  const [review, setReview] = useState([]);

  const { user, token } = isAutheticated();

  const preload = (user, token) => {
    showAllReview(user._id, token).then((data) => {
      if (data.error) {
        console.log(data.error);
      } else {
        setReview(data);
      }
    });
  };

  const deleteThisReview = (reviewId) => {
    deleteReview(reviewId, user._id, token).then((data) => {
      if (data.error) {
        console.log(data.error);
      } else preload(user, token);
    });
  };

  useEffect(() => {
    preload(user, token);
  }, []);

  return (
    <Base>
      {review.map((reviews, index) => {
        return (
          <div key={index} className="row">
            <div className="col">
              {[...Array(5)].map((star, i) => {
                i = i + 1;
                return (
                  <FaStar
                    size="20"
                    color={reviews.stars >= i ? "#ffc107" : "#e4e5e9"}
                  />
                );
              })}
            </div>
            <div className="col">
              <a href={`https://wa.me/91${reviews.phonenumber}`}>
                {reviews.phonenumber}
              </a>
            </div>
            <div className="col">
              {reviews.description ? reviews.description : "-"}
            </div>
            <div className="col">{reviews.createdAt.slice(0, 10)}</div>
          </div>
        );
      })}
    </Base>
  );
};

export default ShowReview;
