import { API } from "../../backend";

export const getProducts = (query = "_id") => {
  return fetch(`${API}/products${query}`, {
    method: "GET",
  })
    .then((respone) => {
      return respone.json();
    })
    .catch((err) => console.log(err));
};

export const getProductCount = () => {
  return fetch(`${API}/products/count`, {
    method: "GET",
  })
    .then((respone) => {
      return respone.json();
    })
    .catch((err) => console.log(err));
};
