import React, { useEffect } from "react";
import { FaStar, FaWhatsapp } from "react-icons/fa";

import Aos from "aos";
import "aos/dist/aos.css";

import Base from "./Base";

import logo from "../logo.png";
import MheaderImage from "../header-mobile.png";
import headerImage from "../header.png";

import ethnicSilde1 from "../eSilde1.png";
import ethnicSilde2 from "../esilde2.jpg";
import ethnicSilde3 from "../esilde3.jpg";
import formalSilde1 from "../fSilde1.png";
import formalSilde2 from "../fSilde2.png";
import formalSilde3 from "../fSilde3.png";
import causalSilde1 from "../cSilde1.jpg";
import causalSilde2 from "../cSilde2.png";
import causalSilde3 from "../cSilde3.png";
import childernSilde1 from "../chSilde1.jpg";
import childernSilde2 from "../chSilde2.png";
import childernSilde3 from "../chSilde3.png";
import border from "../border.png";
import redBorder from "../borderRed.png";
import YellowBorder from "../yellowBorder.png";
import brandLogo1 from "../logo1.png";
import brandLogo2 from "../logo2.png";

import brandLogo3 from "../logo3.png";
import brandLogo4 from "../logo4.png";
import brandLogo5 from "../logo5.png";

import { Route, Link } from "react-router-dom";
import ImageSlider from "./ImageSlider";

export default function Home() {
  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);

  return (
    <Base MenuClassName="navbar navbar-expand-lg navbar-dark navbar-home">
      <a
        href="https://wa.me/918767572679?text=Hello"
        class="float"
        target="_blank"
      >
        <FaWhatsapp size="40" className="my-float" />
      </a>
      <section className="home">
        <div className="row">
          <img src={border} className="borders" alt="" />
          <div className="col-sm-12 col-lg-4 text-center">
            <div className="slider">
              <div className="slider-text1">Trusted since 1942</div>
              <div className="slider-text2">Home Production</div>
              <div className="slider-text3">Manufacturing Rate </div>
            </div>
          </div>
          <div className="col-sm-12 col-lg-8">
            <img className="header-img" src={headerImage} alt="Header Image" />
            <img
              className="mobile-header-img"
              src={MheaderImage}
              alt="Header Image"
            />
          </div>
          <img src={border} className="border1" alt="" />
        </div>
      </section>

      <div className="row">
        <div className="col-lg-6">
          {ImageSlider(ethnicSilde1, ethnicSilde2, ethnicSilde3)}
        </div>

        <div className="col-lg-6 " data-aos="slide-left">
          <img src={redBorder} className="redBorder" />

          <div className="container text-center">
            <img src={logo} width="60" className="logoimg mb-4" />
            <br></br>
            <h1>
              <span>E</span>thnic <span>W</span>ear
            </h1>
            <h3>
              Starting from<span> ₹ 599</span>
            </h3>

            <Link to={`/product/ethnic`} className="btn btn-warning mt-2">
              View More
            </Link>
          </div>
          <img src={YellowBorder} className="YellowBorder" />
        </div>
      </div>

      <div className="row hmm">
        <div className="col-lg-6 " data-aos="slide-right">
          <img src={redBorder} className="redBorder" />

          <div className="container text-center">
            <img src={logo} width="60" className="logoimg mb-4" />
            <br></br>
            <h1>
              <span>F</span>ormal <span>W</span>ear
            </h1>
            <h3>
              Starting from<span> ₹ 299</span>
            </h3>

            <Link to={`/product/formal`} className="btn btn-warning mt-2">
              View More
            </Link>
          </div>
          <img src={YellowBorder} className="YellowBorder" />
        </div>
        <div className="col-lg-6">
          {ImageSlider(formalSilde1, formalSilde2, formalSilde3)}
        </div>
      </div>

      <div className="row">
        <div className="col-lg-6">
          {ImageSlider(causalSilde1, causalSilde2, causalSilde3)}
        </div>
        <div className="col-lg-6" data-aos="slide-left">
          <img src={redBorder} className="redBorder" />

          <div className="container text-center">
            <img src={logo} width="60" className="logoimg mb-4" />
            <br></br>
            <h1>
              <span>C</span>asual <span>W</span>ear
            </h1>
            <h3>
              Starting from<span> ₹ 599</span>
            </h3>

            <Link to={`/product/casual`} className="btn btn-warning mt-2">
              View More
            </Link>
          </div>
          <img src={YellowBorder} className="YellowBorder" />
        </div>
      </div>

      <div className="row hmm">
        <div className="col-lg-6 col-sm-push-12" data-aos="slide-right">
          <img src={redBorder} className="redBorder" />

          <div className="container text-center">
            <img src={logo} width="60" className="logoimg mb-4" />
            <br></br>
            <h1>
              <span>K</span>ids <span>W</span>ear
            </h1>
            <h3>
              Starting from<span> ₹ 120 </span>
            </h3>

            <Link to={`/product/kids`} className="btn btn-warning mt-2">
              View More
            </Link>
          </div>
          <img src={YellowBorder} className="YellowBorder" />
        </div>
        <div className="col-lg-6">
          {ImageSlider(childernSilde1, childernSilde2, childernSilde3)}
        </div>
      </div>

      <div className="corporates">
        <h1 className="text-center m-4 customer">Our Corporate Customer</h1>

        <div
          id="carouselExampleControls"
          class="carousel slide corporate"
          data-ride="carousel"
        >
          <div class="carousel-inner container">
            <div class="carousel-item active">
              <div className="row">
                <div className="col-4">
                  <img
                    class="d-block w-100 brand-logo"
                    src={brandLogo4}
                    alt="First slide"
                  />
                </div>
                <div className="col-4">
                  <img
                    class="d-block w-100 brand-logo"
                    src={brandLogo2}
                    alt="First slide"
                  />
                </div>
                <div className="col-4">
                  <img
                    class="d-block w-100 brand-logo"
                    src={brandLogo3}
                    alt="First slide"
                  />
                </div>
              </div>
            </div>

            <div class="carousel-item">
              <div className="row">
                <div className="col-4 offset-2">
                  <img
                    class="d-block w-100  brand-logo"
                    src={brandLogo1}
                    height="100px"
                    alt="First slide"
                  />
                </div>
                <div className="col-4 ">
                  <img
                    class="d-block w-100 brand-logo"
                    src={brandLogo5}
                    alt="First slide"
                  />
                </div>
              </div>
            </div>
          </div>
          <a
            class="carousel-control-prev"
            href="#carouselExampleControls"
            role="button"
            data-slide="prev"
          >
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="sr-only">Previous</span>
          </a>
          <a
            class="carousel-control-next"
            href="#carouselExampleControls"
            role="button"
            data-slide="next"
          >
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="sr-only">Next</span>
          </a>
        </div>
        {/* mobile  */}
        <div className="corporate-mobile">
          <div
            id="carouselExampleControls"
            class="carousel slide"
            data-ride="carousel"
          >
            <div class="carousel-inner container">
              <div class="carousel-item active">
                <div className="row">
                  <div className="col">
                    <img
                      class="d-block w-100 brand-logo"
                      src={brandLogo4}
                      alt="First slide"
                    />
                  </div>
                  <div className="col">
                    <img
                      class="d-block w-100 brand-logo"
                      src={brandLogo2}
                      alt="First slide"
                    />
                  </div>
                  {/* <div className="col-4">
                    <img
                      class="d-block w-100 brand-logo"
                      src={brandLogo3}
                      alt="First slide"
                    />
                  </div> */}
                </div>
              </div>

              <div class="carousel-item">
                <div className="row">
                  <div className="col">
                    <img
                      class="d-block w-100  brand-logo"
                      src={brandLogo1}
                      height="100px"
                      alt="First slide"
                    />
                  </div>
                  <div className="col">
                    <img
                      class="d-block w-100 brand-logo"
                      src={brandLogo5}
                      alt="First slide"
                    />
                  </div>
                </div>
              </div>
            </div>
            <a
              class="carousel-control-prev"
              href="#carouselExampleControls"
              role="button"
              data-slide="prev"
            >
              <span
                class="carousel-control-prev-icon"
                aria-hidden="true"
              ></span>
              <span class="sr-only">Previous</span>
            </a>
            <a
              class="carousel-control-next"
              href="#carouselExampleControls"
              role="button"
              data-slide="next"
            >
              <span
                class="carousel-control-next-icon"
                aria-hidden="true"
              ></span>
              <span class="sr-only">Next</span>
            </a>
          </div>
        </div>
      </div>
    </Base>
  );
}
