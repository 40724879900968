import React from "react";
import "../styles.css";
import Menu from "./Menu";
import { FaWhatsapp } from "react-icons/fa";

import logo from "../logo.png";

const Base = ({
  title = "My Titlte",
  description = "My Description",
  className = "",
  MenuClassName = "navbar navbar-expand-lg navbar-light bg-dark",
  children,
}) => {
  return (
    <div>
      <Menu className={MenuClassName} />
      <div className="">
        <div className={className}>{children}</div>
      </div>
      <footer>
        <div class="container-fluid p-0">
          <div class="row text-left">
            <div class="col-md-5 col-sm-5">
              <p class="text-muted">
                <img src={logo} alt="logo" width="40" className="footer-logo" />
                <sapn className="footer-logo-name">Moolchand Sons</sapn>
              </p>
              <p class="pt-4 text-muted">
                Copyright ©2020 All rights reserved | This website is design by
                Harshad Birajdar
              </p>
            </div>
            <div class="col-md-5 col-sm-12">
              <h4 class="text-light con">CONTACT INFO</h4>

              <p class=" text-muted mb-1">
                <i class="fa fa-phone mr-2 "></i>
                +91 20 24432828 / +91 20 24461125
              </p>

              <p>
                <p className="text-muted mt-2">
                  <i class="fa fa-envelope mr-2 "></i>
                  moolchandsons@yahoo.com
                </p>
              </p>
            </div>
            <div class="col-md-2 col-sm-12">
              <h4 class="text-light">FOLLOW US</h4>
              <p class="text-muted">Let us be social</p>
              <div class="column text-light">
                <i class="fab fa-facebook-f"></i>
                <i class="fab fa-instagram"></i>
                <i class="fab fa-twitter"></i>
                <i class="fab fa-youtube"></i>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};
export default Base;
