import React, { useState, useEffect, useReducer, useLayoutEffect } from "react";
import Base from "../core/Base";
import { Link } from "react-router-dom";
import { isAutheticated } from "../auth/helper";
import { getProducts, deleteProduct } from "../admin/helper/adminapicall";
import { getProductCount } from "../core/helper/coreapicalls";
const queryString = require("query-string");

const ManageProducts = ({ location }) => {
  const [products, setProducts] = useState([]);
  const [count, setCount] = useState(8);
  const [defaultcount, setDefaultcount] = useState(2);

  const { user, token } = isAutheticated();

  const preload = (hg) => {
    getProducts(hg).then((data) => {
      if (data.error) {
        console.log(data.error);
      } else {
        setProducts(data);
      }
    });
  };

  const prodcutCount = () => {
    getProductCount().then((data) => {
      if (data.error) {
        console.log(data.error);
      } else {
        console.log(data);

        setCount(data);
      }
    });
  };

  useEffect(() => {
    preload();
    // prodcutCount();
  }, []);
  const deleteThisProduct = (productId) => {
    deleteProduct(productId, user._id, token).then((data) => {
      if (data.error) {
        console.log(data.error);
      } else {
        prodcutCount();
        preload("limit=3");
      }
    });
  };

  const showLoadMoreButton = () => {
    if (count <= 8) {
      return (
        <div>
          <button
            onClick={() => {
              preload("limit=2");
              console.log("cliclk", products);
            }}
            className="btn btn-info"
          >
            Load More
          </button>
        </div>
      );
    }
  };
  return (
    <Base headerClassName="navbar navbar-expand-lg fix-top bg-dark">
      <h2 className="mb-4">All Product</h2>
      <Link className="btn btn-info" to={`/admin/dashboard`}>
        <span className="">Admin Home</span>
      </Link>
      <div className="row">
        <div className="col-12">
          <h2 className="text-center text-drak my-3">Total {count} products</h2>
          {products.map((product, index) => {
            return (
              <div key={index} className="row text-center mb-2 ">
                <div className="col-4">
                  <h3 className="text-drak text-left">{product.name}</h3>
                </div>
                <div className="col-4">
                  <Link
                    className="btn btn-success"
                    to={`/admin/product/update/${product._id}`}
                  >
                    <span className="">Update</span>
                  </Link>
                </div>
                <div className="col-4">
                  <button
                    onClick={() => {
                      deleteThisProduct(product._id);
                    }}
                    className="btn btn-danger"
                  >
                    Delete
                  </button>
                </div>
              </div>
            );
          })}
          {showLoadMoreButton()}
        </div>
      </div>
    </Base>
  );
};

export default ManageProducts;
