import React from "react";
import ImageHelper from "./helper/ImageHelper";

const Card = ({ product, addToCard = true, removeFromCard = false }) => {
  const cardTitle = product ? `${product.name}` : `No Title in this Product`;
  const cardDescription = product
    ? `${product.description}`
    : `No Description in this Product`;
  const cardPrice = product ? `${product.price}` : `No Pirce in this Product`;

  const showAddToCard = (addToCard) => {
    return (
      addToCard && (
        <button
          onClick={() => {}}
          className="btn btn-block btn-outline-success mt-2 mb-2"
        >
          Add to Cart
        </button>
      )
    );
  };
  const showRemoveFromCard = (removeFromCard) => {
    return (
      removeFromCard && (
        <button
          onClick={() => {}}
          className="btn btn-block btn-outline-danger mt-2 mb-2"
        >
          Remove from cart
        </button>
      )
    );
  };

  return (
    <div className="card text-dark bg-light  text-center ">
      <div className="card-header lead">{cardTitle}</div>
      <div className="card-body">
        <ImageHelper product={product} />
        <p className="lead bg-descrip font-weight-normal text-wrap">
          {cardDescription}
        </p>
        <p className="btn  rounded  btn-sm px-4 btn-price">₹ {cardPrice}</p>
        <div className="row">
          {/* <div className="col-12">{showAddToCard(addToCard)}</div> */}
          <div className="col-12">{showRemoveFromCard(removeFromCard)}</div>
        </div>
      </div>
    </div>
  );
};

export default Card;
