import React from "react";
import Base from "./Base";

const Contact = () => {
  return (
    <Base>
      <div className="container p-4">
        <div className="text-center contact">
          <h1>Contact Us</h1>
          <p>
            Do you have any questions? Please do not hesitate to contact us
            directly
          </p>
        </div>

        <form>
          <div class="form-row">
            <div class="form-group col-md-6">
              <input
                type="text"
                class="form-control"
                id="inputEmail4"
                placeholder="Name"
              />
            </div>
            <div class="form-group col-md-6">
              <input
                type="number"
                class="form-control"
                id="inputPassword4"
                placeholder="Phone Number"
              />
            </div>
          </div>
          <div class="form-group">
            <input
              type="email"
              class="form-control"
              id="inputEmail4"
              placeholder="Email Address"
            />
          </div>
          <textarea
            placeholder="Message...."
            class="form-control"
            id="exampleFormControlTextarea1"
            rows="3"
          ></textarea>

          <button type="submit" class="btn btn-warning mt-4">
            Submit
          </button>
        </form>
      </div>
      <div className="container">
        <h1 className="text-center p-4 mt-4 contact">Get Directions on Map</h1>
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15133.260966227475!2d73.8564951!3d18.5146512!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x4460d93bdb744bb3!2sMoolchand%20Sons!5e0!3m2!1sen!2sin!4v1591301905830!5m2!1sen!2sin"
          width="100%"
          height="600"
          frameborder="0"
          style={{ border: "0" }}
          allowfullscreen=""
          aria-hidden="false"
          tabindex="0"
        ></iframe>
      </div>
    </Base>
  );
};

export default Contact;
