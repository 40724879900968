import React, { useEffect, useState } from "react";
import Base from "../core/Base";
import Card from "../core/Card";
import { getProducts } from "../core/helper/coreapicalls";
const queryString = require("query-string");

const Men = ({ match, location }) => {
  const [products, setProducts] = useState([]);
  const [aproducts, setAproducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  const loadAllProdcut = (hq) => {
    getProducts(hq).then((data) => {
      if (data.error) {
        setError(data.error);
      } else {
        setProducts(data);
        setLoading(false);
      }
    });
  };

  const loadQuery = () => {
    if (location.search) {
      loadAllProdcut(location.search);
    } else {
    }
  };

  useEffect(() => {
    loadAllProdcut(location.search);
    // preload(match.params.categoryId);
  }, []);

  const loadingBar = () => {
    if (loading) {
      return (
        <div className="text-center" style={{ height: "100", width: "100%" }}>
          <div class="spinner-border" role="status" style={{ marginTop: "3%" }}>
            <span class="sr-only">Loading...</span>
          </div>
        </div>
      );
    }
  };

  return (
    <Base>
      <div className="row">
        {loadingBar()}
        <h1
          onClick={() => {
            loadAllProdcut("sortBy=_id");
          }}
        ></h1>
        <div className="row">
          {products
            .filter(
              (category) => category.category.name === match.params.prodcutType
            )
            .map((product, index) => (
              <div key={index} className="col-12 col-lg-4 mb-4">
                <Card product={product} />
              </div>
            ))}
        </div>
      </div>
    </Base>
  );
};

export default Men;
