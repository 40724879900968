import React, { Fragment } from "react";
import { Link, withRouter } from "react-router-dom";
import { signout, isAutheticated } from "../auth/helper";
import logo from "../logo.png";

const currentTab = (history, path) => {
  if (history.location.pathname === path) {
    return { color: "Red" };
  } else {
    return { color: "Yellow" };
  }
};

const Menu = ({
  history,
  className = "navbar navbar-expand-lg navbar-light bg-light",
}) => (
  <nav class={className}>
    <div className="container">
      <Link class="navbar-brand" to="/">
        <img src={logo} alt="Moolchand Sons" width="50" />
        <span>Moolchand Sons</span>
      </Link>
      <button
        class="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbarNav"
        aria-controls="navbarNav"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarNav">
        <ul class="navbar-nav ml-auto">
          <li className="nav-item">
            <Link style={currentTab(history, "/")} className="nav-link" to="/">
              Home
            </Link>
          </li>
          {/* <li className="nav-item">
            <Link
              style={currentTab(history, "/cart")}
              className="nav-link"
              to="/cart"
            >
              Cart
            </Link>
          </li> */}
          {isAutheticated() && isAutheticated().user.role === 0 && (
            <li className="nav-item">
              <Link
                style={currentTab(history, "/user/dashboard")}
                className="nav-link"
                to="/user/dashboard"
              >
                Dashboard
              </Link>
            </li>
          )}
          {isAutheticated() && isAutheticated().user.role === 1 && (
            <li className="nav-item">
              <Link
                style={currentTab(history, "/admin/dashboard")}
                className="nav-link"
                to="/admin/dashboard"
              >
                Dashboard
              </Link>
            </li>
          )}
          {/* {!isAutheticated() && (
            <Fragment>
              <li className="nav-item">
                <Link
                  style={currentTab(history, "/signup")}
                  className="nav-link"
                  to="/signup"
                >
                  Signup
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  style={currentTab(history, "/signin")}
                  className="nav-link"
                  to="/signin"
                >
                  Sign In
                </Link>
              </li>
            </Fragment>
          )} */}

          <li className="nav-item">
            <Link
              style={currentTab(history, "/about")}
              className="nav-link"
              to="/about"
            >
              About Us
            </Link>
          </li>
          <li className="nav-item">
            <Link
              style={currentTab(history, "/c")}
              className="nav-link"
              to="/about"
            >
              Collection
            </Link>
          </li>
          <li className="nav-item">
            <Link
              style={currentTab(history, "/contact")}
              className="nav-link"
              to="/contact"
            >
              Contact Us
            </Link>
          </li>
          {isAutheticated() && (
            <li className="nav-item">
              <span
                className="nav-link text-warning"
                onClick={() => {
                  signout(() => {
                    history.push("/");
                  });
                }}
              >
                Signout
              </span>
            </li>
          )}
        </ul>
      </div>
    </div>
  </nav>

  // <header className="head">
  //   <nav class="navbar navbar-expand-lg nc">
  //     <a class="navbar-brand" href="#">
  //       <img src={logo} width="70" />
  //       Moolchand Sons
  //     </a>
  //     <button
  //       class="navbar-toggler"
  //       type="button"
  //       data-toggle="collapse"
  //       data-target="#navbarNav"
  //       aria-controls="navbarNav"
  //       aria-expanded="false"
  //       aria-label="Toggle navigation"
  //     >
  //       <i class="fas fa-align-right text-light"></i>
  //     </button>
  //     <div class="collapse navbar-collapse" id="navbarNav">
  //       <div class="mr-auto"></div>
  //       <ul class="navbar-nav">
  //         <li className="nav-item">
  //           <Link style={currentTab(history, "/")} className="nav-link" to="/">
  //             Home
  //           </Link>
  //         </li>
  //         <li className="nav-item">
  //           <Link
  //             style={currentTab(history, "/cart")}
  //             className="nav-link"
  //             to="/cart"
  //           >
  //             Cart
  //           </Link>
  //         </li>
  //         {isAutheticated() && isAutheticated().user.role === 0 && (
  //           <li className="nav-item">
  //             <Link
  //               style={currentTab(history, "/user/dashboard")}
  //               className="nav-link"
  //               to="/user/dashboard"
  //             >
  //               U. Dashboard
  //             </Link>
  //           </li>
  //         )}
  //         {isAutheticated() && isAutheticated().user.role === 1 && (
  //           <li className="nav-item">
  //             <Link
  //               style={currentTab(history, "/admin/dashboard")}
  //               className="nav-link"
  //               to="/admin/dashboard"
  //             >
  //               A. Dashboard
  //             </Link>
  //           </li>
  //         )}
  //         {!isAutheticated() && (
  //           <Fragment>
  //             <li className="nav-item">
  //               <Link
  //                 style={currentTab(history, "/signup")}
  //                 className="nav-link"
  //                 to="/signup"
  //               >
  //                 Signup
  //               </Link>
  //             </li>
  //             <li className="nav-item">
  //               <Link
  //                 style={currentTab(history, "/signin")}
  //                 className="nav-link"
  //                 to="/signin"
  //               >
  //                 Sign In
  //               </Link>
  //             </li>
  //           </Fragment>
  //         )}
  //         {isAutheticated() && (
  //           <li className="nav-item">
  //             <span
  //               className="nav-link text-warning"
  //               onClick={() => {
  //                 signout(() => {
  //                   history.push("/");
  //                 });
  //               }}
  //             >
  //               Signout
  //             </span>
  //           </li>
  //         )}
  //       </ul>
  //     </div>
  //   </nav>
  // </header>

  ////////////////////////////////////////////////////////////////////
  // <div className="menu">
  //   <nav class="navbar navbar-expand-lg">
  //     <a class="navbar-brand" href="#">
  //       Navbar
  //     </a>
  //     <button
  //       class="navbar-toggler"
  //       type="button"
  //       data-toggle="collapse"
  //       data-target="#navbarNav"
  //       aria-controls="navbarNav"
  //       aria-expanded="false"
  //       aria-label="Toggle navigation"
  //     >
  //       <span class="navbar-toggler-icon"></span>
  //     </button>
  //     <ul className="nav nav-tabs bg-dark">
  //       <li className="nav-item">
  //         <Link style={currentTab(history, "/")} className="nav-link" to="/">
  //           Home
  //         </Link>
  //       </li>
  //       <li className="nav-item">
  //         <Link
  //           style={currentTab(history, "/cart")}
  //           className="nav-link"
  //           to="/cart"
  //         >
  //           Cart
  //         </Link>
  //       </li>
  //       {isAutheticated() && isAutheticated().user.role === 0 && (
  //         <li className="nav-item">
  //           <Link
  //             style={currentTab(history, "/user/dashboard")}
  //             className="nav-link"
  //             to="/user/dashboard"
  //           >
  //             U. Dashboard
  //           </Link>
  //         </li>
  //       )}
  //       {isAutheticated() && isAutheticated().user.role === 1 && (
  //         <li className="nav-item">
  //           <Link
  //             style={currentTab(history, "/admin/dashboard")}
  //             className="nav-link"
  //             to="/admin/dashboard"
  //           >
  //             A. Dashboard
  //           </Link>
  //         </li>
  //       )}
  //       {!isAutheticated() && (
  //         <Fragment>
  //           <li className="nav-item">
  //             <Link
  //               style={currentTab(history, "/signup")}
  //               className="nav-link"
  //               to="/signup"
  //             >
  //               Signup
  //             </Link>
  //           </li>
  //           <li className="nav-item">
  //             <Link
  //               style={currentTab(history, "/signin")}
  //               className="nav-link"
  //               to="/signin"
  //             >
  //               Sign In
  //             </Link>
  //           </li>
  //         </Fragment>
  //       )}
  //       {isAutheticated() && (
  //         <li className="nav-item">
  //           <span
  //             className="nav-link text-warning"
  //             onClick={() => {
  //               signout(() => {
  //                 history.push("/");
  //               });
  //             }}
  //           >
  //             Signout
  //           </span>
  //         </li>
  //       )}
  //     </ul>
  //   </nav>
  // </div>
);

export default withRouter(Menu);
