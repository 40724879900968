import React, { useState } from "react";
import { FaStar } from "react-icons/fa";
import { Redirect } from "react-router-dom";

import { submitRating } from "../core/helper/ratingHelper";
import { Link } from "react-router-dom";
import { flatMap } from "lodash";
const queryString = require("query-string");

const Rating = ({ location }) => {
  const [stars, setStars] = useState(null);
  const [hover, setHover] = useState(null);
  const [description, setDescription] = useState("");
  const [error, setError] = useState(true);
  const [loadign, setLoading] = useState(false);
  // const [stars, setStars] = useState(null);
  const handleChange = (event) => {
    setDescription(event.target.value);
  };

  const onsubmit = (event) => {
    event.preventDefault();
    // const stars = rating;

    submitRating(location.search, { stars, description })
      .then((data) => {
        if (data.error) {
          console.log(data.error);
          setError(true);
        } else {
          setError(false);
          setStars(null);
          setHover(null);
          setDescription(null);
          setLoading(false);
          console.log("Done");
        }
      })
      .catch((err) => {
        console.log(`error h ${err}`);
      });
  };

  const onSuccess = () => {
    if (!error) {
      // return (
      //   <div class="alert alert-primary" role="alert">
      //     Thank you for your review. for more information{" "}
      //     <Link to="/">click here</Link>
      //   </div>
      // );
      alert("Thank you for your review");
      return <Redirect to="/"></Redirect>;
    }
  };

  const onEroor = (a) => {
    if (!a) {
      return (
        <div class="alert alert-danger" role="alert">
          Please Select the star
        </div>
      );
    }
  };

  return (
    <div className="col-md-6 offset-sm-3 text-left mt-4 ">
      <div className="rating-page">
        {" "}
        {onSuccess()}
        {/* {onEroor()} */}
        <div className="text-center">
          {[...Array(5)].map((star, i) => {
            const ratingValue = i + 1;
            return (
              <label>
                <input
                  type="radio"
                  name="rating"
                  value={ratingValue}
                  onClick={() => {
                    setStars(ratingValue);
                  }}
                />
                <FaStar
                  className="star"
                  color={
                    ratingValue <= (hover || stars) ? "#ffc107" : "#e4e5e9"
                  }
                  size={45}
                  onMouseEnter={() => setHover(ratingValue)}
                  onMouseLeave={() => setHover(null)}
                />
              </label>
            );
          })}
        </div>
        <div class="form-group mt-3">
          <textarea
            placeholder="Write a review"
            class="form-control"
            id="exampleFormControlTextarea1"
            onChange={handleChange}
            value={description}
            rows="3"
          ></textarea>
        </div>
        <button
          className="btn btn-info btn-block"
          onClick={(e) => {
            if (stars > 0) {
              onsubmit(e);
              setLoading(true);
            } else {
              {
                setError(hover);
              }
            }
          }}
          disabled={loadign}
        >
          {loadign && (
            <span
              class="spinner-border spinner-border-sm mr-2"
              role="status"
              aria-hidden="true"
            ></span>
          )}
          {loadign ? "Please Wait..." : "Submit"}
        </button>
      </div>
    </div>
  );
};

export default Rating;
