import React, { useState } from "react";
import { API } from "../../backend";

const ImageHelper = ({ product }) => {
  const [loading, setLoading] = useState(true);

  const imageurl = product
    ? `${API}/product/photo/${product._id}`
    : `https://images.pexels.com/photos/4443538/pexels-photo-4443538.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940`;

  // setLoading(false);

  const loadingBar = () => {
    if (loading) {
      return (
        <div style={{ width: "8em", height: "8em" }}>
          <div
            class="spinner-border text-danger text-center mr-3"
            role="status"
          >
            <span class="sr-only ml-3">Loading...</span>
          </div>
        </div>
      );
    }
  };

  return (
    <div className="rounded  p-2">
      {loadingBar()}
      <img
        src={imageurl}
        style={{ maxHeight: "100%", maxWidth: "100%" }}
        className="mb-3 rounded"
        onLoad={() => {
          setLoading(false);
        }}
      />
    </div>
  );
};

export default ImageHelper;
